import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiManagerService } from 'src/app/api-manager/api-manager.service';
import { LicensesService } from 'src/app/services/licenses.service';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.css']
})
export class EditDetailsComponent implements OnInit {

  loading = false;
  formGroup: FormGroup;
  updateForm: FormGroup;
  updateObj: any = {};
  editOrg = false;
  org_reference_key = '';
  currentUser: any = {};
  org:any = {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditDetailsComponent>,
    private formBwilder: FormBuilder,
    private formBuilder: FormBuilder,
    private licenseService: LicensesService,
    private api: ApiManagerService,
    public snackBar: MatSnackBar,
  ) { 
    
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('profile'));
    this.org_reference_key = this.currentUser?.org_reference_key;
    console.log("organisation reference key is : ", this.org_reference_key);
    // setTimeout(() => {
    // this.createForm();
    // }, 2000);
    this.createForm();
    console.log("data is : ", this.data);
    
    
    
    
    
  }

  closeDialog() {
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

  createForm() {
    console.log("creating form");
    console.log("organisation name is : ", this.data.orgdetails[0]?.name)
    this.formGroup = this.formBwilder.group({
      name: [this.data.orgdetails[0]?.name, [Validators.required]],
      description: [this.data.orgdetails[0]?.description, [Validators.required]],
      address: [this.data.orgdetails[0]?.address, [Validators.required]],
      vision: [this.data.orgdetails[0]?.vision, [Validators.required]],
      mission: [this.data.orgdetails[0]?.mission, [Validators.required]],
      country: [this.data.orgdetails[0]?.country, [Validators.required]],
      state: [this.data.orgdetails[0]?.state, [Validators.required]],
      city: [this.data.orgdetails[0]?.city, [Validators.required]],
      postal_code : [this.data.orgdetails[0]?.postal_code, [Validators.required]],
      street_address : [this.data.orgdetails[0]?.street_address, [Validators.required]],
    });
  }

  onSubmit() {
    console.log(this.formGroup.value);

    this.licenseService.updateOrganization(this.data.orgdetails[0]?.id, this.formGroup.value).subscribe(
      (Response: any) => {
        console.log("response is : ", Response)
        this.data = Response
        this.openSnackBar('Organization details updated successfully', 'CLOSE');
        this.closeDialog()
      },
      (error: any) => {
        console.log("error updating organisation details", error)
        this.openSnackBar('Organization details updated successfully', 'CLOSE');
      }
    );
  }

  updateOrgForm() {
    this.updateForm = this.formBuilder.group({
      name: [this.updateObj?.name, [Validators.required]],
      time_zone: [this.updateObj?.time_zone, [Validators.required]],
      mission: [this.updateObj?.mission, [Validators.required]],
      vision: [this.updateObj?.vision, [Validators.required]],
      address: [this.updateObj?.address, [Validators.required]],

      description: [this.updateObj?.description, [Validators.required]],

      postal_code: [this.updateObj?.postal_code || ''],
      state: [this.updateObj?.state || ''],
      street_address: [this.updateObj?.street_address || ''],

      city: [this.updateObj?.city, [Validators.required]],
      country: [this.updateObj?.country, [Validators.required]],
    });
  }

  enableEditOrganization(value: boolean, data: any) {
    this.updateObj = {};
    this.editOrg = value;
    if (this.editOrg) {
      this.updateObj = data;
      this.updateOrgForm();
    }
  }

  

  setUpdata() {
    
    this.licenseService.getOrganizationByOrRegNo(
      this.org_reference_key
    ).subscribe(
      (response: any) => {
        this.org = response;

        // setTimeout(() => {
        //   this.createForm();
        //   }, 2000);
        console.log("organisation details are : ",response);
      },
      (error) => {
        console.log(error);
    });
    
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

}
