<div class="data">
  <mat-card class="survey-card">
    <div class="heading-section">
      <div class="flex">
        <button mat-icon-button (click)="_location.back()">
          <mat-icon color="primary" class="pointer"
            >arrow_back_ios_new</mat-icon
          >
        </button>
        <div class="flex-self">
          <h1>New Meeting</h1>
          <p *ngIf="!published">This meeting is unpublished and not visible to meeting invitees.</p>
        </div>
      </div>
      <div class="save-survey" align="left">
        <button
          form="formGroup"
          [disabled]="!allowpublish"
          class="save"
          mat-flat-button
          color="primary"
          (click)="publishmeeting(meeting)"
        >
          Publish
        </button>
      </div>
    </div>
  </mat-card>
  <mat-card class="survey-card" *ngIf="!meetingcreated">
    <div class="heading-section">
      <div class="flex">
        <!-- <button mat-icon-button (click)="_location.back()">
          <mat-icon color="primary" class="pointer"
            >arrow_back_ios_new</mat-icon
          >
        </button> -->
        <div class="flex-self">
          <h1>Create New Meeting</h1>
        </div>
      </div>

      <div class="save-survey" align="left">
        <button
          form="formGroup"
          [disabled]="!formGroup.valid"
          class="save"
          mat-flat-button
          color="primary"
        >
          Save Changes
        </button>
      </div>
    </div>
    <br />
    <mat-card-content>
      <form
        class="example-form"
        [formGroup]="formGroup"
        id="formGroup"
        (ngSubmit)="onSubmitMeeting(formGroup.value)"
      >
        <p>
          <strong>Meeting Title</strong>
          <mat-form-field class="example-full-width" appearance="outline">
            <input
              matInput
              value="New Meeting"
              formControlName="meeting_title"
            />
          </mat-form-field>

          <strong>Meeting Address</strong>
          <mat-form-field class="example-full-width" appearance="outline">
            <textarea
              matInput
              matTextareaAutosize
              matAutosizeMinRows="1"
              matAutosizeMaxRows="20"
              formControlName="meeting_address"
            ></textarea>
          </mat-form-field>

        </p>

        <p class="space">
          <strong>Start Time</strong>
          <mat-form-field appearance="outline" class="example-full-width">
            <input
              matInput
              [ngxMatDatetimePicker]="picker1"
              formControlName="start_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker color="primary" #picker1>
            </ngx-mat-datetime-picker>
          </mat-form-field>

          <strong>End Time</strong>
          <mat-form-field appearance="outline" class="example-full-width">
            <input
              matInput
              [ngxMatDatetimePicker]="picker"
              formControlName="end_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker color="primary" #picker>
            </ngx-mat-datetime-picker>
          </mat-form-field>

          <br />
          <!-- <mat-checkbox
            (change)="sendEmail = $event.checked"
            class="example-margin"
            ><p>Enable send email notification!</p></mat-checkbox
          > -->
        </p>
      </form>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="meetingcreated" class="survey-card">
    <div class="heading-section">
      <div class="flex">
        <div class="flex-self">
          <h1>{{ meeting.meeting_title }}</h1>
        </div>
      </div>
      <div class="save-survey" align="left">
        <button mat-button color="primary"
        (click)="EditMeeting(meeting)">
        <span>Edit details</span>
      </button>
      </div>
    </div>
    <mat-card-content>
      <div class="row">
        <p>
          <strong>Start time: </strong>{{ meeting?.start_date | date: "medium" }}
        </p>
      </div>
      <div class="row">
        <p><strong>End time: </strong> {{ meeting?.end_date | date: "medium" }}</p>
      </div>
      <div class="row">
        <p><strong>Address: </strong>{{ meeting?.meeting_address }}</p>
      </div>
      <br />
    </mat-card-content>
  </mat-card>
</div>
