<br>
<br>
<br>

<mat-card class="back-header" *ngIf="!viewContract && !signedDoc && !doc">
  <div class="header-btn">
    <button mat-icon-button cdkFocusInitial>
      <mat-icon color="primary" class="pointer" (click)="_location.back()"
        >arrow_back_ios_new</mat-icon
      >
    </button>
  </div>

  <div class="h-title">
    <h1>Contract Details</h1>
  </div>
</mat-card>

<mat-tab-group *ngIf="!viewContract && !signedDoc && !doc">
  <mat-tab label="Contract Overview">
    <div class="data">
      <div class="content">
        <br />
        <mat-card class="heading-section">
          <div>
            <h2>{{ contractObj?.contract_title }}</h2>

            <p>
              created by
              <i
                >{{ contractObj?.created_by.first_name }}
                {{ contractObj?.created_by.last_name }}</i
              >
              at <i>{{ contractObj?.created_at | date: "medium" }}</i>
            </p>

            <strong>Contract description</strong>
            <div [innerHTML]="contractObj?.description"></div>

            <strong>Contract parties/contractors</strong>
            <div [innerHTML]="contractObj?.parties"></div>

            <strong>Contract document file</strong>
            <div class="sec_doc">
              <button
                class="view-doc-btn"
                mat-button
                (click)="openContractDocument(contractObj, true)"
              >
                <mat-icon>description</mat-icon>
                view
              </button>
            </div>
            <br />

            <br />
          </div>
          <div class="edit-contract-btn">
            <button
              mat-button
              color="primary"
              [disabled]="!(currentUser?.org_permission == 'Admin')"
              (click)="openContractDialog(contractObj)"
            >
              Edit details
            </button>
          </div>
        </mat-card>

        <mat-card class="users-profile">
          <div *ngIf="contractObj?.signatories.length" class="profile">
            <strong>Signatories</strong>
            <mat-list role="list">
              <mat-list-item
                role="listitem"
                *ngFor="let item of contractObj?.signatories"
                >{{ item.first_name }} {{ item.last_name }}
                <mat-divider></mat-divider>
              </mat-list-item>
            </mat-list>
            <br />
            <button
              mat-button
              class="view-doc-btn"
              (click)="openContractDocument(contractObj)"
            >
              <mat-icon>edit_note</mat-icon>
              Sign
            </button>
          </div>
          <div *ngIf="contractObj?.signatories.length" class="profile">
            <strong>Approvers</strong>
            <mat-list role="list">
              <mat-list-item
                role="listitem"
                *ngFor="let item of contractObj?.approvers"
                >{{ item.first_name }} {{ item.last_name }}
                <mat-divider></mat-divider>
              </mat-list-item> </mat-list
            ><br />
            <strong>Approvers Action</strong>
            <div class="approval_">
              <button
                mat-button
                class="view-doc-btn"
                (click)="takeAction('Approved')"
              >
                Approve
              </button>
              <button
                mat-button
                class="view-doc-btn"
                color="warn"
                (click)="takeAction('Rejected')"
              >
                Reject
              </button>
            </div>
          </div>
        </mat-card>

        <mat-card class="other-details">
          <div>
            <strong>Contract start date </strong>
            <h3>
              {{ contractObj?.start_date_time | date: "medium" }}
            </h3>
          </div>
          <div>
            <strong>Contract end date</strong>
            <h3>
              {{ contractObj?.end_date_time | date: "medium" }}
            </h3>
          </div>
          <div>
            <strong>Duration </strong>
            <h3>{{ contractObj?.duration }}</h3>
          </div>
        </mat-card>
      </div>
      <br />
    </div>
  </mat-tab>

  <mat-tab label="Contract feedback">
    <br />
    <div class="heading-section">
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. ium"
          #input
        />
      </mat-form-field>
      <div>
        <button
          mat-button
          class="feedback_btn"
          (click)="openCreateContractFeedbackDialog()"
        >
          Add new feedback
        </button>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- member Column -->
      <ng-container matColumnDef="member">
        <th mat-header-cell *matHeaderCellDef>Name.</th>
        <td mat-cell *matCellDef="let element">
          {{ element.created_by.first_name }} {{ element.created_by.last_name }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="feedback">
        <th mat-header-cell *matHeaderCellDef>Feedback</th>
        <td mat-cell *matCellDef="let element">
          <h2>{{ element.title }}</h2>
          <div [innerHTML]="element.comment" class="comment-section"></div>
          <br />
          <strong>Provided date</strong>
          <p>
            <i>{{ element.created_at | date: "medium" }}</i>
          </p>
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Feedback decision</th>
        <td mat-cell *matCellDef="let element" colspan="2">
          <div class="edit-section">
            <i>{{ element.status }}</i>
            <button
              mat-icon-button
              class="feedback_btn"
              [matMenuTriggerFor]="menu1"
              aria-label="Example icon-button with share icon"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu1="matMenu">
            <button [disabled]="!(currentUser?.id == element?.created_by?.id)" mat-menu-item (click)="openEditContractFeedbackDialog(element)">
              <span> Edit</span>
              <mat-icon color="primary">edit</mat-icon>
            </button>
            <button [disabled]="!(currentUser?.id == element?.created_by?.id)" mat-menu-item (click)="deleteContractFeedback(element.id)">
              <span>Delete</span>
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>
  </mat-tab>
  <mat-tab
    label="Contract Analytics"
    *ngIf="currentUser?.org_permission == 'Admin' || currentUser?.org_permission == 'Creator' "
  >
    <br />
    <div class="flex_1">
      <mat-card class="heading-section_1">
        <table class="table table-borderless" *ngIf="actionsObj.length">
          <thead>
            <tr>
              <th colspan="2">
                <h2>Approvers Actions</h2>
              </th>
            </tr>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Action Done</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let vote of actionsObj">
              <td>
                {{ vote?.action_taker.first_name }}
                {{ vote?.action_taker?.last_name }}
              </td>
              <td>
                {{ vote?.action_done }} at
                <i>{{ vote?.action_done_at | date: "medium" }}</i>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="approval_sec">
          <h2>Statistics</h2>
          <div class="chart-wrapper">
            <canvas
              baseChart
              [data]="pieChartData_"
              [labels]="pieChartLabels_"
              [chartType]="pieChartType_"
              [options]="lineChartOptions"
              [plugins]="pieChartPlugins"
              [legend]="false"
            >
            </canvas>
          </div>
        </div>
      </mat-card>

      <mat-card class="heading-section_1">
        <table class="table table-borderless" *ngIf="signatureObj.length">
          <thead>
            <tr>
              <th colspan="2">
                <h2>Signatories Actions</h2>
              </th>
            </tr>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Signed date</th>
              <!-- <th scope="col">Action</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of signatureObj">
              <td>
                {{ item?.signer.first_name }}
                {{ item?.signer?.last_name }}
              </td>
              <td>
                <i>{{ item?.signed_at | date: "medium" }}</i>
              </td>
              <!-- <td>
                <button mat-button>
                  <mat-icon color="warn">delete</mat-icon> Delete signature
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>

        <div class="approval_sec">
          <h2>Statistics</h2>
          <div class="chart-wrapper">
            <canvas
              baseChart
              [data]="pieChartData"
              [labels]="pieChartLabels"
              [chartType]="pieChartType"
              [options]="pieChartOptions"
              [plugins]="pieChartPlugins"
              [legend]="pieChartLegend"
            >
            </canvas>
          </div>
        </div>
      </mat-card>
    </div>
  </mat-tab>
</mat-tab-group>

<div class="view-agenda-doc" *ngIf="viewContract">
  <contract-signing-viewer
    (close)="viewContract = false"
    class="viewer"
    [pdfSrc] = "pdfSrc"
    [id]="contractid"
  ></contract-signing-viewer>
</div>

<div class="view-agenda-doc" *ngIf="signedDoc">
  <app-view-signed-document
    (close)="signedDoc = false"
    class="viewer"
  ></app-view-signed-document>
</div>

<!-- <div class="view-doc" *ngIf="doc">
  <button mat-button (click)="closeDocumentView()">
    <mat-icon color="warn">close</mat-icon>Close
  </button>
  <app-view-document [pdfSrc] = "pdfSrc"></app-view-document>
</div> -->
