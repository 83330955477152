<!-- <div class="dialog">
    <div class="heading-section">
      <div>
        <button mat-icon-button (click)="onNoClick()">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
      </div>
      <div class="save-changes">
        <button
          mat-button
          (click)="onSubmit(formGroup.value)"
          color="primary"
          form="FormMeeting"
          [disabled]="!formGroup.valid"
          *ngIf="!loading"
          style="font-weight: bold; font-family: latoRegular,sans-serif !important; font-size: 26px;"
        >
          Save Changes
        </button>
        <mat-spinner diameter="40" class="loading-indicator" *ngIf="loading" style="background-color:#201547;">
        </mat-spinner>
      </div>
    </div>
    <br /><br />
    <h2 style="font-weight: bold; font-family: latoRegular,sans-serif !important;">Edit Meeting Details</h2>
    <br />
    <div
      mat-dialog-content
      [formGroup]="formGroup"
      id="FormMeeting"
      class="mat-dialog-content"
    >
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label style="color: #201547; font-weight: bold; font-family: latoRegular,sans-serif !important; font-size: 20px;">Meeting title</mat-label>
        <input matInput value="New Meeting" formControlName="meeting_title" style="color: black; font-family: latoRegular,sans-serif !important;"/>
      </mat-form-field>
  
      <p>
        <mat-form-field appearance="outline">
          <mat-label style="color: #201547; font-weight: bold; font-family: latoRegular,sans-serif !important; font-size: 20px;">Open Date</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="picker1"
            formControlName="start_date"
            style="color: black; font-family: latoRegular,sans-serif !important;"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker color="primary" #picker1>
          </ngx-mat-datetime-picker>
        </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label style=" color: #201547; font-weight: bold; font-family: latoRegular,sans-serif !important; font-size: 20px;">Close Date</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="picker"
            formControlName="end_date"
            style="color: black; font-family: latoRegular,sans-serif !important;"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker color="primary" #picker>
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </p>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label style="color: #201547; font-weight: bold; font-family: latoRegular,sans-serif !important; font-size: 20px;">Meeting address</mat-label>
        <input matInput value="New Meeting" formControlName="meeting_address" style="color: black; font-family: latoRegular,sans-serif !important;" />
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label style=" color: #201547; font-weight: bold; font-family: latoRegular,sans-serif !important; font-size: 20px;">Invitees</mat-label>
        <mat-select
          class="mat-select-user"
          #usersList
          multiple
          formControlName="invitees"
          style="color: black; font-family: latoRegular,sans-serif !important;"
        >
          <mat-option *ngFor="let user of users" [value]="user.id"
            >{{ user.first_name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div> -->

  <div class="dialog">
    <div class="heading-section">
      <div>
        <button mat-icon-button (click)="onNoClick()">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
      </div>
      <div class="save-changes">
        <button
          mat-button
          (click)="onSubmit(usersList.selectedOptions.selected)"
          color="primary"
          [disabled]="!selectedusers"
          style="font-weight: bold; font-family: latoRegular,sans-serif !important; font-size: 26px;"
        >
          Save Changes
        </button>
        <mat-spinner diameter="40" class="loading-indicator" *ngIf="loading" style="background-color:#201547;">
        </mat-spinner>
      </div>

  <mat-tab-group>
    <mat-tab label="Users">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search User" (keyup)="onSearch($event.target.value)">
      </mat-form-field>
      <mat-selection-list #usersList (selectionChange)="selectedOptions($event.option.value, usersList.selectedOptions.selected)" >
        <mat-list-option checkboxPosition="before" *ngFor="let user of filteredOptions" [value]="user" [selected]="user?.selected">
          {{user.first_name}} {{user.last_name}} 
        </mat-list-option>
      </mat-selection-list>
      <!-- <p>
        Options selected: {{usersList.selectedOptions.selected.length}}
      </p> -->
    </mat-tab>
    
    <mat-tab label="Roles">
      <br>
      <br>
      
      <ul style="list-style: none;">
        <li *ngFor="let user of selectedusersoptions" >
          <div class="row">
            <div class="col-lg-6"><p>{{user.first_name}} {{user.last_name}}</p></div>
            <div class="col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>Reader</mat-label>
                <mat-select>
                  <mat-option [value]="role" *ngFor="let role of meetingroles">{{role}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <br>
        </li>
        <br>
      </ul>
      <!-- <mat-selection-list #roles>
        <mat-list-option *ngFor="let user of users">
          {{user.first_name}} {{user.last_name}}
          <p> Add Role </p>
        </mat-list-option>
      </mat-selection-list> -->
    </mat-tab>
    <mat-tab label="Groups">
      <div class="example-full-width">
        <!-- <mat-selection-list #groupsList>
          <app-search-groups-member
              class="example-full-width"
              [bankMultiCtrl]="groupInvitees"
            >
            </app-search-groups-member>
        </mat-selection-list> -->

        <div class="example-full-width">
          <h3>Filter by group members</h3>
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select (selectionChange)="onGroupsChange($event.value)">
              <mat-option [value]="0"> None </mat-option>
              <mat-option [value]="group.id" *ngFor="let group of groupsObj">
                {{ group.group_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="example-full-width" *ngIf="expression">
          <p>Added the following to the selection list in Users tab. You can Deselect them from that point</p>
          <ul>
            <li *ngFor="let bank of banks">{{ bank.first_name}} {{ bank.last_name}}</li>
          </ul>
        </div>
      </div>
      <!-- <p>Group list is {{groupsList.value}}</p> -->
    </mat-tab>
  </mat-tab-group>
    </div>
  </div>
  