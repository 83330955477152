<div class="dialog">
    <div class="heading-section">
      <div>
        <button mat-icon-button (click)="onNoClick()">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
      </div>
      <div class="save-changes">
        <button
          mat-button
          (click)="onSubmit()"
          color="primary"
          form="FormMeeting"
          [disabled]="!formGroup.valid"
          *ngIf="!loading"
          style="font-weight: bold; font-family: latoRegular,sans-serif !important; font-size: 26px;"
        >
          Save Changes
        </button>
        <mat-spinner diameter="40" class="loading-indicator" *ngIf="loading" style="background-color:#201547;">
        </mat-spinner>
      </div>
    </div>
    <br /><br />
    <h2 style="font-weight: bold; font-family: latoRegular,sans-serif !important;">Edit Company Details</h2>
    <br />
    <div
      mat-dialog-content
      [formGroup]="formGroup"
      id="FormMeeting"
      class="mat-dialog-content"
    >
    <mat-form-field
    
    class="example-full-width"
  >
    <mat-label>Organization Title</mat-label>
    <input type="text" formControlName="name" matInput />
  </mat-form-field>

  <mat-form-field
    class="example-full-width"
    appearance="outline"
  >
    <mat-label>About</mat-label>
    <textarea
      matInput
      matTextareaAutosize
      matAutosizeMinRows="3"
      matAutosizeMaxRows="10"
      formControlName="description"
    ></textarea>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="example-full-width"
  >
    <mat-label>Venue Address</mat-label>
    <input type="text" formControlName="address" matInput />
  </mat-form-field>
  <mat-form-field
    class="example-full-width"
    appearance="outline"
  >
    <mat-label>Vision</mat-label>
    <textarea
      matInput
      matTextareaAutosize
      matAutosizeMinRows="3"
      matAutosizeMaxRows="10"
      formControlName="vision"
    ></textarea>
  </mat-form-field>
  <mat-form-field
    class="example-full-width"
    appearance="outline"
  >
    <mat-label>Mission</mat-label>
    <textarea
      matInput
      matTextareaAutosize
      matAutosizeMinRows="3"
      matAutosizeMaxRows="10"
      formControlName="mission"
    ></textarea>
  </mat-form-field>
  <p>
    <mat-form-field
      class="example-full-width"
      appearance="outline"
    >
      <mat-label>Country</mat-label>
      <input matInput formControlName="country" />
    </mat-form-field>
    <mat-form-field
      class="example-full-width"
      appearance="outline"
    >
      <mat-label>State</mat-label>
      <input matInput formControlName="state" />
    </mat-form-field>
    <mat-form-field
      class="example-full-width"
      appearance="outline"
    >
      <mat-label>City</mat-label>
      <input matInput formControlName="city" />
    </mat-form-field>

    <mat-form-field
      class="example-full-width"
      appearance="outline"
    >
      <mat-label>Postal Code</mat-label>
      <input
        matInput
        value="Coseke Rwanda"
        formControlName="postal_code"
      />
    </mat-form-field>
    <mat-form-field
      class="example-full-width"
      appearance="outline"
    >
      <mat-label>Street Address</mat-label>
      <input
        matInput
        value="Coseke Rwanda"
        formControlName="street_address"
      />
    </mat-form-field>      
    </div>
  </div>
  