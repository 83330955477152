import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiManagerService } from 'src/app/api-manager/api-manager.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-compliance-view',
  templateUrl: './compliance-view.component.html',
  styleUrls: ['./compliance-view.component.css'],
})
export class ComplianceViewComponent implements OnInit {
  sessionStorage = window.sessionStorage;
  complianceOb: any = {};
  checkList: any = [];
  complianceId: any = '';
  currentUser: any = {};
  evidence : any;
  param : any
  ans : any
  myResponses : any[]
  constructor(
    private sharedService: SharedService,
    public _location: Location,
    private apiManager: ApiManagerService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private _route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this._route.params.subscribe((params) => {
      if (params['compliance']) {
        this.loadCompliance(params['compliance']);
      } else this._location.back();
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.sessionStorage.getItem('profile'));
    this.initializePage();
  }

  initializePage() {}

  complyCheck(item_id, ans) {
    // const compliance_id = this.complianceOb.id;
    this.param = {
      item_id : item_id,
      check_status: ans,
    };
    console.log("evidence is", this.evidence)
    console.log("param is", this.param)
    if (this.evidence?.item_id === item_id ) 
      {
        this.param['check_evidence'] = this.evidence?.evidence;

        for(let x of this.checkList){
          if(x.id == item_id){
            console.log("compliance item is ", x)
            x.check_evidence = this.evidence?.evidence
            x['submit'] = true
          }
        }
      }
    // this.apiManager.markChecklist(compliance_id, check_id, param).subscribe({
    //   next: (res: any) => {
    //     this.updateChecklist(res);
    //   },
    //   error: (err) => console.log(err),
    // });
    
  }
  onSubmit(item : any){
    const compliance_id = this.complianceOb.id;
    console.log("item is ----", item?.id)
    if(item?.id === this.param?.item_id){
      console.log("this param is ----", this.param)
      const param = {
        check_status: this.param?.check_status,
      }
      this.apiManager.markChecklist(compliance_id, this.param?.item_id, this.param).subscribe({
        next: (res: any) => {
          this.updateChecklist(res);
          this.openSnackBar('Checklist updated', 'Close');
        },
        error: (err) => console.log(err),
      });
    }
    // const param = {
    //   check_status: this.ans,
    // };
    // if (this.evidence) param['check_evidence'] = this.evidence;
    // this.apiManager.markChecklist(compliance_id, check_id, param).subscribe({
    //   next: (res: any) => {
    //     this.updateChecklist(res);
    //   },
    //   error: (err) => console.log(err),
    // });
  }
  oncontentchange(itemid : any, change : any){
    console.log("change is --- : ", change)
    console.log("item id is --- : ", itemid)
    this.evidence = {
      item_id : itemid,
      evidence: change,
    }
    console.log("evidence is --- : ", this.evidence)
  }

  updateChecklist(obj) {

    console.log("response from checklist", obj)
    for (let i = 0; i <= this.checkList.length; i++) {
      if (this.checkList[i].id === obj?.checklist.id) {
        this.checkList[i] = obj?.checklist;
        this.checkList[i].check_status = obj?.check_status
        this.checkList[i].check_evidence = obj?.check_evidence 
        this.checkList[i]['submit'] = false
        return;
      }
    }
  }

  openEditComplianceDialog(data): void {
    const dialogRef = this.dialog.open(DialogEditComplianceDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const path = '/admin/compliance-view';
        const param = {
          compliance: this.sharedService.encryptData(result),
        };
        this.sharedService.navigaTo(path, param);
      }
    });
  }
  openEditComplianceCheckDialog(data): void {
    const dialogRef = this.dialog.open(DialogEditComplianceCheckDialog, {
      restoreFocus: false,
      width: '500px',
      panelClass: ['animate__animated', 'animate__fadeInRight'],
      height: '100vh',
      position: {
        bottom: '0px',
        right: '0px',
      },
      data: data,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result == 'OK') {
          this.getChecklist();
        } else {
          this.updateChecklist(result);
        }
      }
    });
  }

  getChecklist() {
    const startloading = this.apiManager.startLoading()
    const compliance_id = this.complianceOb.id;
    this.apiManager.getCheclist(compliance_id).subscribe({
      next: (res : any) => {
        this.checkList = res?.checklist;
        this.myResponses = res?.my_responses;
        console.log("my responses is ---", this.myResponses)
      },
      error: (err) => console.log(err),
    });

    setTimeout(() => {
      if(this.myResponses?.length > 0){
        console.log("responses present")
        for(let item of this.checkList){
          for(let response of this.myResponses){
            if(item.id == response.checklist.id){
              item['check_evidence'] = response.check_evidence
              item['check_status'] = response.check_status
            }
          }
        }
      }
    }, 1000);
    

    
    setTimeout(() => {
      this.apiManager.stopLoading(startloading)
      console.log("updated checklist is ", this.checkList)
    }, 3000);
  }

  loadCompliance(complianceObj: any) {
    this.complianceOb = this.sharedService.decryptData(complianceObj);
    console.log("compliance object is", this.complianceOb)
    if (this.complianceOb) {
      this.getChecklist();
    } else this._location.back();
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}

@Component({
  selector: 'dialog-edit-compliance-dialog',
  templateUrl: 'dialog-edit-compliance-dialog.html',
  styleUrls: ['./compliance-view.component.css'],
})
export class DialogEditComplianceDialog {
  formGroup: FormGroup;
  loading = false;
  title = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogEditComplianceDialog>,
    private formBwilder: FormBuilder,
    private apiManager: ApiManagerService
  ) {}

  ngOnInit() {
    this.title = this.data?.action;
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBwilder.group({
      compliance_name: [this.data.compliance_name, [Validators.required]],
      compliance_start_date: [
        this.data.compliance_start_date,
        [Validators.required],
      ],
      compliance_end_date: [
        this.data.compliance_end_date,
        [Validators.required],
      ],
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit(data: any) {
    const compliance_id = this.data.id;
    this.loading = true;
    this.apiManager.updateCompliance(compliance_id, data).subscribe({
      next: (res) => {
        this.loading = false;
        this.dialogRef.close(res);
      },
      error: (err) => {
        console.log(err);
        this.loading = false;
      },
    });
  }
}

@Component({
  selector: 'dialog-edit-compliance-check-dialog',
  templateUrl: 'dialog-edit-compliance-check-dialog.html',
  styleUrls: ['./compliance-view.component.css'],
})
export class DialogEditComplianceCheckDialog {
  formGroup: FormGroup;
  loading = false;
  title = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogEditComplianceCheckDialog>,
    private formBwilder: FormBuilder,
    private apiManager: ApiManagerService
  ) {}

  ngOnInit() {
    this.title = this.data?.action;
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBwilder.group({
      check_name: [this.data.check_name, [Validators.required]],
      check_description: [this.data.check_description, [Validators.required]],
      check_status: [this.data.check_status || false],
      check_evidence: [this.data.check_evidence || ''],
      check_evidence_document: [],
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSubmit(data: any) {
    const check_id = this.data.id;
    this.loading = true;
    this.apiManager.updateComplianceCheck(check_id, data).subscribe({
      next: (res) => {
        this.loading = false;
        this.dialogRef.close(res);
      },
      error: (err) => {
        console.log(err);
        this.loading = false;
      },
    });
  }

  deleteComplianceCheck(check_id) {
    this.loading = true;
    this.apiManager.deleteComplianceChecklist(check_id).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.dialogRef.close(res.status);
      },
      error: (err) => {
        this.loading = false;
        console.log(err);
      },
    });
  }

  
}
