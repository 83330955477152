import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiManagerService } from 'src/app/api-manager/api-manager.service';
export interface user {
  id : "";
  first_name: "";
  last_name : "";
  selected ?: boolean;
}

@Component({
  selector: 'app-edit-invitees',
  templateUrl: './edit-invitees.component.html',
  styleUrls: ['./edit-invitees.component.css']
})
export class EditInviteesComponent implements OnInit {

  groupsObj: any = [];
  protected banks: any = [];
  formGroup: FormGroup;
  meeting: any = {};
  users: any[] = [];
  loading = false;
  meetingroles : any[] = ["Admin","Contributor","Reader"]
  title = '';
  sessionStorage = window.sessionStorage;
  filteredOptions : any[ ] = []
  currentuser : any
  selectedusers = false
  userroles : any[] = []
  selectedusersoptions : any[] = []
  meetinginvitees :any[] = []
  expression: boolean = false;
  groupInvitees: any = new FormControl([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditInviteesComponent>,
    private formBwilder: FormBuilder,
    private apiManager: ApiManagerService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    console.log("our meeting is ---", this.data.meeting)
    this.meeting = this.data.meeting;
    this.getinvitees()
    this.users = this.data.users;
    this.filteredOptions = this.users
    console.log("Users are", this.users)
    this.createForm();
    this.currentuser = JSON.parse(this.sessionStorage.getItem('profile'));
    this.updatefilteredoptions()
    this.getAllGroups();
  }
  getAllGroups() {
    this.apiManager.getAllUserGroups().subscribe({
      next: (res) => {
        this.groupsObj = res;
      },
      error: (err) => {},
    });
  }
  onGroupsChange(group_id) {
    if (group_id) {
      
      const spinner = this.apiManager.startLoading('Please wait...');
      this.apiManager.getUsersByGroupId(group_id).subscribe({
        next: (res) => {
          this.banks = res;
          this.expression = true;
          console.log("banks is ---", this.banks)
          // this.setupInitial();
          this.addselectedfilteredoptions()
          this.apiManager.stopLoading(spinner);

          this.selectedusers = true
        },
        error: (err) => {
          this.apiManager.stopLoading(spinner);
        },
      });
    } else {
      this.expression = false;
      this.banks = [];
    }
  }

  // setupInitial() {
  //   // load the initial bank list
  //   this.filteredBanksMulti.next(this.banks.slice());

  //   // listen for search field value changes
  //   this.bankMultiFilterCtrl.valueChanges
  //     .pipe(takeUntil(this._onDestroy))
  //     .subscribe(() => {
  //       this.filterBanksMulti();
  //     });
  // }
  addselectedfilteredoptions(){
    console.log("meeting invitees",this.banks)
    for(let x =0; x < this.filteredOptions.length; x+=1){
      for(let y =0; y < this.banks.length; y+=1){
        if(this.banks[y].id == this.filteredOptions[x].id){
          this.filteredOptions[x].selected = true
          this.selectedusersoptions.push(this.filteredOptions[x])
        }
      }

      
    console.log("updated filtered options is ---", this.filteredOptions)
    }
    
  }
  updatefilteredoptions(){
    console.log("meeting invitees",this.meetinginvitees)
    for(let x =0; x < this.filteredOptions.length; x+=1){

      if(this.meetinginvitees.includes(this.filteredOptions[x].id)){
        this.filteredOptions[x].selected = true
        this.selectedusersoptions.push(this.filteredOptions[x])
      }
    console.log("updated filtered options is ---", this.filteredOptions)
    }
    
  }
  getinvitees(){
    this.meeting.invitees.forEach(item => {
      this.meetinginvitees.push(item.id)
    })
    console.log("meeting invitees are", this.meetinginvitees)
  }
  createForm() {
    this.formGroup = this.formBwilder.group({
      meeting_title: [this.meeting?.meeting_title, [Validators.required]],
      start_date: [this.meeting?.start_date, [Validators.required]],
      end_date: [this.meeting?.end_date, [Validators.required]],
      meeting_address: [this.meeting?.meeting_address, [Validators.required]],
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onSearch(data : any){
    console.log(data)
    this.filteredOptions = this.users.filter(item =>
      item.first_name.includes(data)
    );
  }
  selectedOptions(data: any, list : any){
    console.log("selected options are", data)
    this.selectedusersoptions = []
    for(let x = 0; x < list.length; x+=1){
      this.selectedusersoptions.push(list[x].value)
    }
    // this.selectedusersoptions = data
    console.log("list is", this.selectedusersoptions)
    this.selectedusers = true
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
  onSubmit(data: any) {
    var newdata = []
    data.forEach(item => {
      console.log(item.value)
      newdata.push(item.value.id);
    });
    const spinner = this.apiManager.startLoading('Updating meeting..');
    // console.log("meeting id is", this.meeting.id)
    console.log("data is ---", newdata)
    const meeting_id = this.meeting.id;
    this.loading = true;
    console.log(meeting_id, newdata);
    var datatosend = {
      invitees : newdata
    }
    this.apiManager.updateMeeting(meeting_id, datatosend).subscribe({
      next: (res) => {
        console.log("response is ---",res)
        this.apiManager.stopLoading(spinner);
        this.loading = false;
        this.dialogRef.close(res);
        this.openSnackBar('Meeting updated successfully', 'Close');
      },
      error: (err) => {
        this.apiManager.stopLoading(spinner);
        console.log(err);
        this.loading = false;
        this.openSnackBar('Oops! Error while updating meeting!', 'Close');
      },
    });
  }


}
